.footer {
    background-color: #1f769e;
    color: aliceblue;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer p {
    margin-left: 20px;
}

.footer a {
    margin-right: 20px;
}

#logo_company {
    width: 100px;
}