.portofolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

#media-img {
    width: 80vh;
}
