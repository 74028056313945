.header {
    background-color: #1f769e;
    color: aliceblue;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#logo_soft {
    width: 100px;
}

/* Sidenav menu */
.sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: -250px;
    background-color: #206585;
    padding-top: 60px;
    transition: left 0.5s ease;
}

.sidenav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Sidenav menu links */
.sidenav .header-menu-item a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: aliceblue;
    display: block;
    transition: 0.3s;
}

.sidenav .header-menu-item a:hover {
    color: rgb(152, 181, 207);
}

/* Active class */
.sidenav.active {
    left: 0;
}

/* Close btn */
.sidenav .close {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
}

.burger-icon span {
    display: block;
    width: 35px;
    height: 5px;
    background-color: aliceblue;
    margin: 6px 0;
}

.header-menu-item {
    font-size:large;
}

.close {
    background-color: #206585;
    color: aliceblue;
    border: none;
}

.close:hover {
    color: rgb(193, 212, 230);
}

.openBtn {
    background-color: #1f769e;
    color: aliceblue;
    border: none;
}
