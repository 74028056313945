.members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.box-dev {
    color: aliceblue;
    margin: 40px;
    width: 500px;
    display: flow-root;
    justify-content: space-between;
    align-items: center;
}

.dev {
    background-color: #1f769e;
    color: aliceblue;
    margin: 40px;
    padding: 10px;
    border-radius: 25px;
    justify-content: space-between;
    align-items: center;
}

#dev-img {
    border-radius: 10px;
}

.links {
    color: aliceblue;
}